var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[_c('b-form',[_c('EKDialog',{ref:"facultieDialog",attrs:{"title":_vm.title,"placeholder":!_vm.isEdit ? 'ابحث عن كلية محددة' : '',"btnText":!_vm.isEdit ? 'كلية جديد' : '',"isEdit":!!_vm.facultyDto.id},on:{"ok":_vm.submit,"close":function($event){return _vm.$store.commit('Set_Facultie_Dto')},"delete":function($event){return _vm.deleteFaculty(_vm.facultyDto.id)},"search":_vm.search},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('EKInputText',{attrs:{"rules":[
                        { type: 'required', message: 'اسم الكلية إجباري' }
                    ],"label":"اسم الكلية","placeholder":"ادخل اسم الكلية","name":"name"},model:{value:(_vm.facultyDto.name),callback:function ($$v) {_vm.$set(_vm.facultyDto, "name", $$v)},expression:"facultyDto.name"}}),_c('EKInputSelect',{attrs:{"label":"الجامعة","placeholder":"اختر جامعة","rules":[
                        {
                            type: 'required',
                            message: 'يجب تحديد حقل الجامعة'
                        }
                    ],"options":_vm.universitiesList,"name":"university","clearable":true},model:{value:(_vm.facultyDto.universityId),callback:function ($$v) {_vm.$set(_vm.facultyDto, "universityId", $$v)},expression:"facultyDto.universityId"}}),_c('EKInputText',{attrs:{"rules":[
                        { type: 'required', message: 'عدد السنوات إجباري' },
                        { type: 'min_value:1', message: 'عدد السنوات يجب ان يكون اكبر او يساوي الواحد' }
                    ],"label":"عدد السنوات","placeholder":"ادخل عدد السنوات","name":"numOfYears","type":"number"},model:{value:(_vm.facultyDto.numOfYears),callback:function ($$v) {_vm.$set(_vm.facultyDto, "numOfYears", $$v)},expression:"facultyDto.numOfYears"}}),_c('EKInputImage',{attrs:{"label":"صورة الكلية","title":"أدرج الملف هنا أو انقر للرفع","required":"","value":_vm.facultyDto.imagePath
                            ? _vm.$store.getters['app/domainHost'] +
                              '/' +
                              _vm.facultyDto.imagePath
                            : null},on:{"input":function($event){_vm.facultyDto.file = $event}}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }